import { CurrencySettings, type CurrencySetting } from "@looksrare/config";
import { getCurrencyDisplayDecimals } from "../currency";

import { formatNumberToLocale } from "./formatNumberToLocale";
import { formatToSignificant } from "./formatToSignificant";
import { fromDecimals } from "./fromDecimals";
import { BigIntish } from "../types";

interface FormatToDisplayDecimalsInput {
  amountWei: BigIntish;
  currency: {
    symbol: keyof CurrencySettings; // Not `Currency` (symbol) for better compatibility, as `Currency` in typegen does not know about our Currency symbol type
    decimals?: number;
  };
  size?: keyof CurrencySetting["displayDecimals"];
  showChevronBelowLowerBound?: boolean;
  zeroCharacter?: string;
}

export const formatToDisplayDecimals = ({
  amountWei,
  currency,
  size = "default",
  showChevronBelowLowerBound = true,
  zeroCharacter = "0",
}: FormatToDisplayDecimalsInput) => {
  const currencyOptions = currency.decimals ? { decimals: currency.decimals } : undefined;
  const amountFloat = Number(fromDecimals(amountWei, currencyOptions));

  if (amountFloat === 0) {
    return zeroCharacter;
  }

  const displayDecimals = getCurrencyDisplayDecimals(currency.symbol, size);
  const minAmount = 1 / 10 ** displayDecimals;

  if (amountFloat < minAmount && showChevronBelowLowerBound) {
    return `<${formatNumberToLocale(minAmount, displayDecimals, displayDecimals)}`;
  }

  return formatToSignificant(amountWei, displayDecimals, currencyOptions);
};
