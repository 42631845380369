import { useEffect, useMemo, useState } from "react";
import { Grid, GridProps, HStack, Stack } from "@chakra-ui/react";
import { useAccount } from "wagmi";
import { ChainId } from "@looksrare/sdk-v2";
import { useBlockNumber, useSoundEffect, useWidgetBotCrate } from "@looksrare/utils";
import { SelfExclusionModal, useWidgetBotOptions } from "@looksrare/uikit";
import { MoDAssetPair, MoDContract } from "@looksrare/yolo-games-gql-typegen";
import { RoundEntry } from "../../components/RoundEntry/RoundEntry";
import { useMoDCurrentRound, useMoDNextRound, useMoDRecentRounds, useMoDRound } from "../../network/graphql/hooks";
import { YourEntry } from "../../components/YourEntry/YourEntry";
import { ClaimButton } from "../../components/ClaimButton/ClaimButton";
import { TradingViewModal } from "../../components/TradingViewModal/TradingViewModal";
import { useMoDConfig } from "../../config";
import { MoDCharts } from "./components/MoDCharts";
import { MoDTopBar } from "./components/MoDTopBar";
import { MoDDataBar } from "./components/MoDDataBar";
import { MoDUfo } from "./components/MoDUfo";
import { MoDRoundEnd } from "./components/MoDRoundEnd";
import { MoDMobileMenu } from "./components/MoDMobileMenu";

const justClosedBufferMs = 15_000;

interface MoDMainViewProps extends GridProps {
  chainId: ChainId;
  contract: MoDContract;
  assetPair: MoDAssetPair;
}

export const MoDMainView = ({ chainId, contract, assetPair, ...props }: MoDMainViewProps) => {
  useWidgetBotCrate(useWidgetBotOptions());

  const { selfTimeoutUntil, isWalletBlocked, isMuted } = useMoDConfig();
  const { play: playBgMusic } = useSoundEffect({ path: "/sounds/moon-or-doom/bg_track.mp3", volume: 0.3, isMuted });

  useEffect(() => {
    playBgMusic({ restartIfAlreadyPlaying: false, loop: true });
  }, [playBgMusic]);

  const [isRoundTransitionSnoozed, setIsRoundTransitionSnoozed] = useState(false);

  const handleSnoozeTransition = () => {
    setIsRoundTransitionSnoozed(true);
    setTimeout(() => setIsRoundTransitionSnoozed(false), justClosedBufferMs * 2);
  };

  const { address } = useAccount();

  const { data: recentRounds, refetch: refetchRecentRounds } = useMoDRecentRounds({
    filter: { contract },
    player: address,
  });
  const { data: currentRound, refetch: refetchCurrentRound } = useMoDCurrentRound({ contract, player: address });
  const { data: nextRound, refetch: refetchNextRound } = useMoDNextRound({ contract, player: address });

  const currentRoundOnChainId = currentRound?.onChainId;
  const { data: previousRound } = useMoDRound(
    {
      contract,
      player: address,
      id: currentRoundOnChainId && currentRoundOnChainId > 0 ? currentRoundOnChainId - 1 : undefined,
    },
    { enabled: !!currentRoundOnChainId }
  );

  useBlockNumber({
    onBlock: () => {
      refetchRecentRounds();
      refetchCurrentRound();
      refetchNextRound();
    },
  });

  const pastRounds = useMemo(() => {
    return recentRounds?.slice(2); // removes current and next round
  }, [recentRounds]);

  const userEntry = address ? currentRound?.entries?.[0] : undefined;
  const currentRoundClosedAt = currentRound?.closedAt ? new Date(currentRound.closedAt).getTime() : Date.now();
  const previousRoundClosedAt = previousRound?.closedAt ? new Date(previousRound.closedAt).getTime() : undefined;
  const isCurrentRoundClosed = currentRoundClosedAt <= Date.now();
  const hasPreviousRoundJustClosed = !!previousRoundClosedAt && previousRoundClosedAt + justClosedBufferMs > Date.now();
  const isRoundTransitioning = !isRoundTransitionSnoozed && (isCurrentRoundClosed || hasPreviousRoundJustClosed);

  return (
    <>
      <Grid
        position="relative"
        width="100%"
        gap={8}
        alignItems="start"
        templateColumns={{ base: "1fr", xl: "1fr 320px" }}
        {...props}
      >
        <MoDUfo
          position="absolute"
          right={8}
          bottom={-16}
          zIndex={0}
          width="300px"
          height="300px"
          assetPair={assetPair}
          currentRoundLockPrice={currentRound?.lockPrice ? Number(currentRound.lockPrice) : undefined}
          opacity={isRoundTransitioning ? 0.2 : 1}
          transition="opacity 200ms"
          display={{ base: "none", xl: "block" }}
        />

        <Stack spacing={4} flex={1} height="100%">
          <MoDTopBar chainId={chainId} assetPair={assetPair} currentRound={currentRound} />

          <Stack
            spacing={4}
            flex={1}
            height="100%"
            p={4}
            border="1px solid"
            borderColor="border-01"
            borderRadius="dialog"
          >
            {!!currentRound?.lockPrice && (
              <MoDDataBar
                assetPair={assetPair}
                lockPrice={Number(currentRound.lockPrice)}
                moonPayoutRatio={currentRound.moonPayoutRatio}
                doomPayoutRatio={currentRound.doomPayoutRatio}
                moonAmount={currentRound.moonAmount}
                doomAmount={currentRound.doomAmount}
                status={currentRound.status}
                entryType={userEntry?.moonPosition ? "moon" : "doom"}
                entryAmountInEth={userEntry?.amount}
                opacity={isRoundTransitioning ? 0.2 : 1}
                transition="opacity 200ms"
              />
            )}

            <HStack spacing={4} alignItems="start" height="100%">
              <Stack position="relative" spacing={0} height="100%" width="100%">
                {!!currentRound && (
                  <MoDRoundEnd
                    isCurrentRoundClosed={isCurrentRoundClosed}
                    isRoundTransitioning={isRoundTransitioning}
                    onClickSnooze={handleSnoozeTransition}
                    previousRound={previousRound}
                  />
                )}

                <MoDCharts
                  assetPair={assetPair}
                  pastRounds={pastRounds}
                  currentRound={currentRound}
                  nextRound={nextRound}
                  height="100%"
                  width="100%"
                  opacity={isRoundTransitioning ? 0.5 : 1}
                  transition="opacity 200ms"
                  pb={{ base: "none", sm: userEntry ? "0px" : "62px" }}
                />

                {userEntry && (
                  <YourEntry
                    entryType={userEntry.moonPosition ? "moon" : "doom"}
                    entryAmount={userEntry.amount}
                    size="lg"
                    width="100%"
                    display={{ base: "none", sm: "flex" }}
                  />
                )}
              </Stack>
            </HStack>
          </Stack>
        </Stack>

        <Stack
          spacing={4}
          height="100%"
          justifyContent="space-between"
          opacity={isRoundTransitioning ? 0.2 : 1}
          transition="opacity 200ms"
          display={{ base: "none", xl: "flex" }}
        >
          <Stack spacing={4}>
            <ClaimButton chainId={chainId} />

            {!!nextRound && (
              <RoundEntry
                chainId={chainId}
                contract={contract}
                round={nextRound}
                onEntry={refetchNextRound}
                isBlocked={isWalletBlocked}
              />
            )}
          </Stack>
        </Stack>
      </Grid>

      <MoDMobileMenu
        chainId={chainId}
        contract={contract}
        nextRound={nextRound}
        onEntry={refetchNextRound}
        position="fixed"
        top="auto"
        left={{ base: 0, md: 20 }}
        right={0}
        bottom={0}
        display={{ base: "flex", xl: "none" }}
        zIndex={2}
      />
      <TradingViewModal />

      {!!selfTimeoutUntil && (
        <SelfExclusionModal endDate={selfTimeoutUntil}>
          <ClaimButton chainId={chainId} />
        </SelfExclusionModal>
      )}
    </>
  );
};
